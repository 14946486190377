//paul mise en place le lundi 30 septembre
import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const mainFont = 'dinot'
const fontSecondary = 'garage' //fontFamily: `${fontSecondary}, sans-serif`,
const fontTertiary = 'eurostyle'

const white = '#fff'
const black = '#000'
const pageBackgroundColor = '#FDFCFD'
const primaryMain = '#000'
const primaryLight = '#E5E9EB'
const secondaryMain = '#000'
const greyLight = '#F7F7F9'

const imp = '!important'

// const f11 = `11px`
const f12 = `12px`
const f13 = `13px`
const f14 = `14px`
const f15 = `15px`
const f16 = `16px`
const f18 = `18px`

const f20 = `20px`
const f21 = `21px`
const f22 = `22px`
const f24 = `24px`
const f25 = `25px`
const f26 = `26px`
const f27 = `27px`
const f30 = `30px`
const f32 = `32px`
const f34 = `34px`
const f35 = `35px`
const f40 = `40px`
const f55 = `55px`

const body = {
  fontSize: f14,
  lineHeight: 1.6,
}
const body2 = {
  fontSize: f13,
  lineHeight: 1.67,
}

const theme = createMuiTheme({
  themeFont: {
    //WARNING FONT NAME WITH SPACE SHOULD HAVE A +. NEXT JS TRIGGER BAD URL FOR GOOGLE FONT
    main: 'dinot',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${mainFont}, sans-serif`,
    h1: {
      lineHeight: 1.15,
      ...heading(f32, f40, 700, 700, fontSecondary, fontSecondary, false),
    },
    h2: {
      lineHeight: 1.15,
      ...heading(f24, f40, 700, 700, fontSecondary, fontSecondary, false),
    },
    h3: {
      lineHeight: 1.4,
      ...heading(f18, f21, 700, 700, fontSecondary, fontSecondary, false),
    },
    h4: {
      lineHeight: 1.55,
      ...heading(f16, f16, 700, 700, fontSecondary, fontSecondary, false),
    },
    h5: {
      lineHeight: 1.25,
      ...heading(f14, f14, 700, 700, fontSecondary, fontSecondary, false),
    },
    h6: {
      lineHeight: 1.25,
      ...heading(f12, f12, 700, 700, fontSecondary, fontSecondary, false),
    },
    body1: {
      ...body,
    },
    body2: {
      ...body2,
    },
    caption: {
      fontSize: f12,
      lineHeight: 1.17,
      fontWeight: 400,
    },
    button: {
      fontFamily: `${mainFont}, sans-serif`,
      fontSize: f12,
      fontWeight: 700,
      lineHeight: 1.67,
      textTransform: 'uppercase',
    },
  },
  palette: {
    common: {
      black: primaryMain,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: greyLight,
    primaryTransparent: {
      main: primaryMain,
      light: primaryLight,
    },
    secondaryTransparent: {
      main: primaryMain,
      light: primaryLight,
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#FFEA28',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: '#D5D2D9',
    // greyLight: '#F5F3F8',
    greyLight: '#E5E9EB',
    greyMedium: '#C0BCC6',
    greyText: '#6F677B',
  },
  shadows: [
    'none',
    '0px 0px 15px rgba(46, 46, 46, 0.1)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.page': {
          // backgroundColor: pageBackgroundColor,
        },
        '.hero__home': {
          '& .title': {
            fontSize: 55,
            /*'@media (min-width: 1366px)': {
              fontSize: 55,
            }, */
            '@media (max-width: 1024px)': {
              marginBottom: '35px !important',
            },
          },
          '& .tagline': {
            fontSize: '15px !important',
            '& p': {
              margin: 0,
            },
            '@media (max-width: 1024px)': {
              marginBottom: '0 !important',
              fontSize: '18px !important',
            },
          },
        },
        '.hero': {
          '&__title': {
            '@media (max-width: 1024px)': {
              fontSize: '2.3rem !important',
            },
          },
        },
        '.header': {
          '&__logo': {
            height: '35px!important',
            '@media (min-width: 1024px)': {
              height: '35px!important',
            },
          },
          '& .menu': {
            '&__link': {
              fontFamily: `${fontSecondary}, sans-serif`,
              fontWeight: 700,
              fontSize: f21,
              //lineHeight: 1.6,
            },
            '&__item': {
              '&:after': {
                display: 'none',
              },
            },
          },
        },

        '.banner': {
          '& .paragraph-class': {
            fontFamily: `${mainFont}, sans-serif`,
            fontWeight: 400, //`400 ${imp}`,
            fontSize: f12,
            lineHeight: 1.6,
          },
        },

        '.shop': {
          '&__planner': {
            '@media (max-width: 1024px)': {
              marginTop: '-11.375rem !important',
            },
          },
        },

        '.productCard': {
          '&__title': {
            textTransform: 'uppercase !important',
            fontSize: '21px !important',
            fontFamily: `${fontSecondary}, sans-serif`,
          },
          '&__amount': {
            fontSize: '16px !important',
          },
          '&__cta': {
            color: `${white} !important`,
          },
        },
        '.product': {
          '&__title': {
            textTransform: 'uppercase !important',
            fontSize: '38px !important',
          },
          '&__head': {
            flexDirection: 'column !important',
            alignItems: 'flex-start !important',
          },
          '&-accordion': {
            '&__summary p': {
              fontFamily: `${fontSecondary}, sans-serif`,
              fontWeight: 700,
              fontSize: '28px !important',
            },
            '&__details p': {
              fontSize: '14px !important',
            },
          },
          '&__price': {
            fontSize: '28px !important',
          },
        },

        '.size-modal': {
          '&__title': {
            fontSize: `${f24} !important`,
            '@media (max-width: 1024px)': {
              fontSize: `${f20} !important`,
            },
          },
        },

        '.size-card': {
          '&__title': {
            fontSize: `${f34} !important`,
            '@media (max-width: 1024px)': {
              fontSize: `${f30} !important`,
            },
          },
        },

        '.sizes': {
          '&__title': {
            '@media (max-width: 1024px)': {
              fontSize: `${f25} !important`,
            },
          },
        },

        '.basket': {
          '&__popup': {
            '&__title': {
              '@media (min-width: 1024px)': {
                fontSize: `${f30} !important`,
              },
            },
          },
          '&__title': {
            fontSize: `${f22} !important`,
            '@media (max-width: 1024px)': {
              fontSize: `${f25} !important`,
            },
          },

          '#basket--funnel &__title': {
            fontSize: `${f25} !important`,
          },
        },

        '.shipping-infos': {
          '&__start, &__end': {
            fontSize: f16,
            '& .MuiTypography-body': {
              '&1,&2': {
                fontSize: `${f15} !important`,
              },
            },
          },
          '&__wrapper': {
            '& .MuiTypography-body2': {
              fontSize: f15,
            },
          },
        },

        '.cart-count': {
          '@media (min-width: 1024px)': {
            fontSize: f22,
          },
        },

        '.funnel': {
          '&__header, &__rental-summary': {
            '& .MuiTypography-h3': {
              fontSize: f27,
              '@media (min-width: 1024px)': {
                fontSize: f30,
              },
            },
          },
          '&__nav--active': {
            '@media (min-width: 1024px)': {
              fontSize: f25,
            },
          },
        },

        '.payment': {
          '&-coupon,&-bycard': {
            '& .MuiTypography-h5': {
              fontSize: f25,
            },
          },
        },

        '.section-hiw': {
          '&__title': {
            fontSize: f55,
          },
        },

        '.hiw': {
          '&__icon': {
            '&>img': {
              '@media (min-width: 1024px)': {
                width: '140px',
              },
            },
          },
          '&__title': {
            fontSize: f26,
          },
          '&__desc': {
            fontSize: f16,
          },
        },

        '.section-infos': {
          '&__content': {
            '& h2': {
              fontFamily: `${fontSecondary}, sans-serif`,
              fontSize: '40px !important',
            },
            '& p': {
              fontSize: '16px !important',
            },
          },
        },
        '.account': {
          backgroundColor: `${white} !important`,
          '&__title': {
            fontSize: '60px !important',
          },
          '&__questions': {
            fontSize: '35px !important',
          },
          '&__infos-link,&__question,&__answer': {
            fontSize: `${f16} !important`,
          },

          '&-infos': {
            '&__title': {
              '@media (min-width: 480px)': {
                fontSize: `${f40} !important`,
              },
            },
          },
          '&-informations': {
            '&__title': {
              '@media (min-width: 480px)': {
                fontSize: `${f35} !important`,
              },
            },
          },
        },
        '.footer': {
          '&__logo': {
            minHeight: '2.1875rem',
          },
        },
      },
    },
    MuiButton: {
      root: {
        padding: '12px 45px',
        borderRadius: 0,
        //backgroundColor: `${black}`, //non car sinon effet de bord et c'est appliqué partout
        border: `2px solid ${white}`, //${imp}
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '12px 45px',
      },

      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      outlinedPrimary: {
        border: `1px solid ${black}`, //${imp}
        backgroundColor: 'transparent',
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiSelect: {
      root: {
        color: primaryMain,
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: black,
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: secondaryMain,
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
